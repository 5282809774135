<template>
  <div class="home" v-if="$store.state.app.refreshHome > 0">
    <div class="row">
      <div class="widget-box" v-for="(widget, index) in list" :key="index">
        <div class="dashboard-box">
          <div class="head">
            {{ widget.widgetName }}
            <br />
            <span v-html="widget.icon"></span>
          </div>
          <div class="info">
            {{
              $utils.formatBigNumberForUniversal(parseInt(widget.widgetValue))
            }}
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6"> <order-count-widget /></div>
      <div class="col-xs-12 col-sm-12 col-md-6">
        <order-detail-count-widget />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4"><approve-system-widget /></div>
      <div class="col-xs-12 col-sm-12 col-md-8"><certificate-list /></div>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import OrderCountWidget from "./OrderCountWidget";
  import OrderDetailCountWidget from "./OrderDetailCountWidget";
  import CertificateInfoWidget from "./CertificateWidget";
  import ApproveSystemWidget from "./ApproveSystemWidget";

  export default {
    name: "Home-Page",
    components: {
      "order-count-widget": OrderCountWidget,
      "order-detail-count-widget": OrderDetailCountWidget,
      "approve-system-widget": ApproveSystemWidget,
      "certificate-list": CertificateInfoWidget
    },
    data: function () {
      return {
        list: {}
      };
    },
    watch: {
      list() {}
    },
    methods: {
      async getDashboard() {
        let response = await axios.get("dashboard");

        if (this.$utils.isResponseOk(response)) {
          this.list = response.data;
        }
      }
    },
    async mounted() {
      this.getDashboard();
    }
  };
</script>

<style>
  .widget-box {
    margin-left: 0.525em;
  }

  .dashboard-box {
    border-radius: 0.325em;
    border: solid 1px #dedede;
    background-color: #f7f7f7;
    padding: 0.625em;
    margin: 0.625em;
    display: table;
    width: auto;
    min-width: 250px;
    max-width: 250px;
    min-height: 120px;
  }

  .dashboard-box .head {
    color: #333;
    display: table-cell;
    vertical-align: middle;
    padding: 0.625em;
    text-align: center;
  }

  .dashboard-box .head span > i {
    display: flex;
    justify-content: center;
    padding-top: 0.425em;
  }

  .dashboard-box .info {
    font-size: 350%;
    color: #333;
    display: table-cell;
    padding: 0.625em;
    vertical-align: middle;
    text-align: right;
  }

  .dateFilter {
    width: 230px !important;
    float: right !important;
    margin-right: 15px;
  }

  .refreshButton {
    margin-right: 10px;
    float: right;
    color: darkRed;
  }
</style>
