import { pageStateEnum } from "../enums/pageStateEnum";
import { parse, stringify } from "flatted/esm";
import app from "@/common/constants/app";
import CtsToastr from "@/components/ui-components/CtsToastr/CtsToastr"; /* eslint-disable valid-typeof */
import main from "@/main.js";
import objectPermissionHelper from "@/common/helpers/objectPermissionHelper";
import store from "@/store";

const utils = {
  get(elementId) {
    return document.getElementById(elementId);
  },
  unCheck(area) {
    let checkboxes = document.querySelectorAll(area + " input[type=checkbox]");

    for (let i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
    }
  },
  getRowId(arr) {
    let id = 1;

    if (arr.length > 0) {
      let result = arr.filter((x) => x.Status != app.dataStates.Deleted);

      id = result.length + 1;
    }

    return id;
  },
  getTextOfSelectInput(id, value) {
    let field = document.getElementById(id);

    if (typeof value !== "undefined") {
      for (let i = 0; i < field.options.length; i++) {
        if (value == field.options[i].value) {
          return field.options[i].text;
        }
      }
    } else {
      return field.options[field.selectedIndex].text;
    }
  },
  isNullOrUndefined(element) {
    return !(typeof element !== "undefined" && element != null);
  },
  isNullOrWhiteSpace(value) {
    return (
      typeof value === "undefined" ||
      typeof value === "" ||
      value == null ||
      value === ""
    );
  },
  isArrayHasElement(array) {
    return typeof array !== "undefined" && array.length > 0;
  },
  matchRegex(regex, value) {
    let _regex = new RegExp(regex, "gm"),
      result = _regex.exec(value);

    return result != null ? result.length : false;
  },
  checkValidation(area) {
    let fields;

    app.requiredFieldsByArea.empty();

    if (typeof area !== "undefined") {
      fields = document.querySelectorAll(area + " .requiredField");
    } else {
      fields = document.querySelectorAll(".requiredField");
    }

    for (let i = 0; i < fields.length; i++) {
      this.checkElementValidation(fields[i]);
    }

    if (app.requiredFieldsByArea.find((x) => x == true)) {
      return false;
    } else {
      app.isFormValid = true;
      return true;
    }
  },
  checkElementValidation(element) {
    if (typeof element.target !== "undefined") {
      element = element.target;
    }

    let inputValue = element.value,
      regexPattern = element.attributes["data-val-regex-pattern"];

    if (!this.isNullOrWhiteSpace(inputValue)) {
      element.classList.remove("requiredFieldError");

      if (!this.isNullOrWhiteSpace(regexPattern)) {
        let result = this.matchRegex(regexPattern.value, inputValue);

        if (result) {
          window.isValid = true;
          element.classList.remove("requiredFieldError");
        } else {
          element.classList.add("requiredFieldError");
          app.requiredFieldsByArea.push(true);
        }
      }
    } else {
      element.classList.add("requiredFieldError");
      app.requiredFieldsByArea.push(true);
    }
  },
  checkSelectedRows(rows) {
    if (rows.length == 1) {
      // console.log(app.selectedRows);
    } else if (rows.length > 1) {
      // showNoty("Please select only 1 row for edit.", "warning");
    } else {
      // showNoty("Please select a row for edit.", "warning");
    }
  },
  sortByColumn(property, sortway) {
    let sortOrder = 1;

    if (sortway === "desc") {
      sortOrder = -1;
    }

    return function (a, b) {
      let result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;

      return result * sortOrder;
    };
  },
  addClass(element, classList) {
    for (var cls of classList) {
      if (!element.classList.contains(cls)) {
        element.classList.add(cls);
      }
    }

    return element;
  },
  removeClass(element, classList) {
    for (var cls of classList) {
      if (element.classList.contains(cls)) {
        element.classList.remove(cls);
      }
    }

    return element;
  },
  findChild(element, selector) {
    var childs = element.querySelectorAll(selector);

    if (childs.length > 0 && childs.length == 1) {
      return childs[0];
    } else {
      return element.querySelectorAll(selector);
    }
  },
  isResponseOk(response) {
    return response.status === 200;
  },
  isResponseReturnedOk(response) {
    return (
      response.status === 200 &&
      typeof response.data.state !== undefined &&
      response.data.state
    );
  },
  validationControl(obj, response) {
    response = response ? response : [];

    if (typeof obj.$v !== "undefined") {
      obj.$v.$touch();

      if (obj.$v.$invalid || obj.$v.$error) {
        this.showWarningMessage(main.dictionary.MSG_FILL_REQUIRED_FIELDS);
        response.push(false);
      }
    }

    return response;
  },
  checkAndRunValidation(self, ch, response) {
    let children = self && self.$parent ? self.$parent.$children : ch;

    response = response ? response : [];

    if (self && self.$parent) {
      response = this.validationControl(self.$parent, response);
    }

    if (children.length) {
      children.forEach((child) => {
        let subChildren = child.$children ? child.$children : [];

        if (subChildren.length) {
          this.checkAndRunValidation(null, subChildren, response);
        }

        response = this.validationControl(child, response);
      });
    }

    return response;
  },
  checkAndRunUnSavedData(self, ch, response) {
    let children = self && self.$parent ? self.$parent.$children : ch.$children;

    if (children.length) {
      children.forEach((child) => {
        if (!this.isNullOrUndefined(child.hasChangedList)) {
          response = child.hasChangedList();
        } else {
          response = this.checkAndRunUnSavedData(null, child, response);
        }
      });
    }

    return !response;
  },
  isAddForm(url) {
    var urlParts = url.split("/");

    if (
      isNaN(parseInt(urlParts[urlParts.length - 1])) &&
      urlParts[urlParts.length - 1] == "form"
    ) {
      return true;
    }
    return false;
  },
  convertToDate(value, culture = "tr-tr", showTime = false) {
    if (!this.isNullOrWhiteSpace(value) && value.indexOf("T") > -1) {
      return (
        new Date(value).toLocaleDateString(culture) +
        (showTime ? " " + new Date(value).toLocaleTimeString(culture) : "")
      );
    } else if (!this.isNullOrWhiteSpace(value) && value.indexOf("Date(") > -1) {
      let pattern = /Date\(([^)]+)\)/,
        results = pattern.exec(value),
        dt = new Date(parseFloat(results[1]));

      return dt.getDate() + "." + (dt.getMonth() + 1) + "." + dt.getFullYear();
    } else {
      if (!this.isNullOrUndefined(value) && !showTime) {
        return value.split(" ")[0];
      }

      // var dt = new Date(value);
      // var offset = dt.getTimezoneOffset() / 60; = -3

      return value;
    }
  },
  convertDateToTimeZone(value, header) {
    let timeZoneFilter = app.timeZones.filter(
      (x) =>
        (x.page.toLowerCase() == "common" &&
          x.columns.filter(
            (y) => y.toLowerCase() == header.column.toLowerCase()
          ).length > 0) ||
        (x.page.toLowerCase() == header.table.toLowerCase() &&
          x.columns.filter(
            (y) => y.toLowerCase() == header.column.toLowerCase()
          ).length > 0)
    );
    if (
      !this.isNullOrWhiteSpace(value) &&
      timeZoneFilter != null &&
      timeZoneFilter.length > 0 &&
      store.state.login.user.timeZone != null
    ) {
      const newDate = new Date(
        value.replace(/(..).(..).(....) (..):(..):(..)/, "$3-$2-$1 $4:$5:$6")
      );

      newDate.setMinutes(
        newDate.getMinutes() + store.state.login.user.timeZone.gtm * 60
      );

      let day = newDate.getDate().toString(),
        month = (newDate.getMonth() + 1).toString();

      if (day.length == 1) {
        day = "0" + day;
      }

      if (month.length == 1) {
        month = "0" + month;
      }

      return (
        day +
        "." +
        month +
        "." +
        newDate.getFullYear() +
        " " +
        newDate.getHours() +
        ":" +
        newDate.getMinutes() +
        ":" +
        newDate.getSeconds()
      );
    }
    return value;
  },
  convertToStringToDate(date) {
    let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1,
      day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

    return date.getFullYear() + "-" + month + "-" + day;
  },
  clearVirtualObject(data) {
    for (const key in data) {
      let value = data[key],
        ctorName = value != null ? data[key].constructor.name : "";

      if (ctorName === "Object") {
        data[key] = null;
      } else if (ctorName === "String" && value !== "") {
        let dateRegex = /^[0-9]{2}[.]{1}[0-9]{2}[.]{1}[0-9]{4}$/g,
          regexResult = dateRegex.test(value);

        if (regexResult) {
          data[key] =
            regexResult != null
              ? value.replace(/(..).(..).(....)/, "$2.$1.$3")
              : "";
        }
      }
    }
  },
  refreshFormPage(router, id) {
    let path = router.currentRoute.path;

    if (router.currentRoute.query.isCopy) {
      path = path.split("/");
      path.splice(3);
      path = path.join("/");
    }
    router.push(path + "/" + id);
  },
  getJsonValuesFromObj(obj) {
    let arr = [];

    Object.keys(obj).forEach((i) => {
      arr = obj[i];
    });

    return arr;
  },
  clearObjectKeys(obj, restrictedKeys) {
    for (const key of Object.keys(obj)) {
      if (key.indexOf("dynamic_") > -1) {
        continue;
      }

      if (restrictedKeys.indexOf(key) === -1) {
        switch (typeof obj[key]) {
          case "number":
            if (key === "id") {
              obj[key] = Math.floor(Math.random() * -1000000);
            } else {
              obj[key] = 0;
            }
            break;
          case "object":
            obj[key] = null;
            break;
          case "string":
            obj[key] = "";
            break;
          default:
            break;
        }
      }
    }
  },
  generateNewRand() {
    return (Date.now() * Math.random() * -1)
      .toString()
      .replace(".", "")
      .substr(0, 10);
  },
  generateNewRowByHeaders(headers, list, externalParameters, restrictedKeys) {
    let newRow = {};

    restrictedKeys =
      typeof restrictedKeys === "undefined" ? [] : restrictedKeys;

    if (list.length > 0) {
      let lastObj = parse(stringify(list[list.length - 1])),
        lookups = headers.filter((header) => {
          return !this.isNullOrWhiteSpace(header.lookupUrl);
        });

      for (const key of lookups) {
        restrictedKeys.push(key.customFieldName);
      }

      this.clearObjectKeys(lastObj, restrictedKeys);
      lastObj.rowStatus = pageStateEnum.CREATED;
      lastObj.id = parseInt(this.generateNewRand());
      newRow = lastObj;
    } else {
      for (const header of headers) {
        newRow.id = parseInt(this.generateNewRand());
        newRow.rowStatus = pageStateEnum.CREATED;

        if (
          header.column.indexOf(".") > -1 &&
          !this.isNullOrWhiteSpace(header.columnType)
        ) {
          header.dataType = header.columnType;
        }

        switch (header.dataType) {
          case "int":
          case "decimal":
          case "double":
          case "float":
            if (
              restrictedKeys.indexOf(header.column) === 0 &&
              typeof externalParameters[header.column] === "undefined"
            ) {
              newRow[header.column] = Math.floor(Math.random() * -1000000);
            } else {
              newRow[header.column] = 0;
            }
            break;
          case "string":
            if (!this.isNullOrWhiteSpace(header.updateFieldOnChange)) {
              newRow[header.updateFieldOnChange] = "";
            }

            if (header.column.indexOf(".") > -1) {
              let splittedColumnNames = header.column.split(".");

              newRow[splittedColumnNames[0]] = {};
            } else {
              newRow[header.column] = "";
            }
            break;
          case "date":
            newRow[header.column] = new Date().toLocaleString("tr-tr");
            break;
          default:
            break;
        }

        if (
          !this.isNullOrUndefined(externalParameters) &&
          !this.isNullOrWhiteSpace(header.externalParameters)
        ) {
          let splittedExternalParams = header.externalParameters.split(",");

          for (const externalParam of splittedExternalParams) {
            let param = externalParameters.filter((item) => {
              return item.key === externalParam;
            });

            if (param.length > 0) {
              newRow[header.updateFieldOnChange] = param[0].value;
            }
          }
        }
      }
    }

    newRow.dateCreated = new Date();
    newRow.isActive = true;
    newRow.isDeleted = false;

    return newRow;
  },
  getDateForLocale(locale, options) {
    return new Date().toLocaleString(locale, options).replace(/\./g, "-");

    // date = splittedDate[2] + "-" + splittedDate[1] + "-" + splittedDate[0];
    // return date;
  },
  canShow(objectIdentifier) {
    return objectPermissionHelper.checkMenuObjectPermissionsSee(
      objectIdentifier
    );
  },
  canModify(objectIdentifier) {
    return objectPermissionHelper.checkMenuObjectPermissionsModify(
      objectIdentifier
    );
  },
  addSearchParameters(
    array,
    dataColumn,
    dataType,
    dataValue,
    filterRule = "",
    isChild = false,
    columnType = "",
    enumType = "",
    inputType = "",
    hasDeletedFlag = false
  ) {
    var searchObj = {
      column: dataColumn,
      dataType: dataType,
      value: dataValue,
      filterRule: filterRule,
      isChild: isChild,
      columnType: columnType,
      enumType: enumType,
      inputType: inputType,
      hasDeletedFlag: hasDeletedFlag
    };

    array.push(searchObj);
  },
  calculateDistribution(distributedValue, references, afterDigit = 2) {
    //* Usage */
    /*
          let results = this.$utils.calculateDistribution(value, values[], 2);
    */

    let results = [];

    if (references.length > 0) {
      references.sort(function (a, b) {
        return a - b;
      });

      let sumOfReferences = references.reduce(
          (a, b) => parseFloat(a) + parseFloat(b),
          0
        ),
        value = parseFloat(distributedValue);

      if (isNaN(value) || isNaN(sumOfReferences)) {
        return NaN;
      }

      for (let index = 0; index < references.length; index++) {
        let result = this.decimalAdjust(
          "round",
          references[index] * (value / sumOfReferences),
          -afterDigit
        );

        if (result == 0) {
          result = this.decimalAdjust(
            "round",
            Math.pow(0.1, afterDigit),
            -afterDigit
          );
        }

        results.push(result);

        sumOfReferences -= parseFloat(references[index]);
        value -= parseFloat(result);
      }

      var sumOfResults = results.reduce(
        (a, b) =>
          parseFloat(a.toFixed(afterDigit)) + parseFloat(b.toFixed(afterDigit)),
        0
      );

      sumOfResults = this.decimalAdjust("round", sumOfResults, -afterDigit);

      if (isNaN(sumOfResults)) {
        return NaN;
      }

      if (sumOfResults !== parseFloat(distributedValue)) {
        return [];
      }
    }

    return results;
  },
  countDecimals(value, isInteger = false) {
    if (isInteger && value % 1 === 0) return 0;
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  },
  decimalAdjust(type, value, exp) {
    // If the exp is undefined or zero...
    if (typeof exp === "undefined" || +exp === 0) {
      return Math[type](value);
    }

    value = +value;
    exp = +exp;

    // If the value is not a number or the exp is not an integer...
    if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0)) {
      return NaN;
    }

    // Shift
    value = value.toString().split("e");
    value = Math[type](+(value[0] + "e" + (value[1] ? +value[1] - exp : -exp)));
    // Shift back
    value = value.toString().split("e");

    return +(value[0] + "e" + (value[1] ? +value[1] + exp : exp));
  },
  replaceTurkishChars(text) {
    text.replace("ğ", "g");
    text.replace("Ğ", "G");
    text.replace("ü", "u");
    text.replace("Ü", "U");
    text.replace("ş", "s");
    text.replace("Ş", "S");
    text.replace("ı", "i");
    text.replace("İ", "I");
    text.replace("ö", "o");
    text.replace("Ö", "O");
    text.replace("ç", "c");
    text.replace("Ç", "C");

    return text;
  },
  showErrorMessage(message = main.dictionary.MSG_ERROR_OCCURED) {
    CtsToastr.showToastr("error", message);
    this.disableLoading();
  },
  showSuccessMessage(message = "") {
    CtsToastr.showToastr("success", message);
    this.disableLoading();
  },
  showWarningMessage(message = "") {
    CtsToastr.showToastr("warning", message);
    this.disableLoading();
  },
  generateInQuery(array, column) {
    let ids = array.map((item) => {
      return column ? item[column] : item.id ? item.id : item;
    });

    return ids.join(",");
  },
  addStatusHeader(showUserColumn) {
    let headers = [
      {
        title: main.dictionary.LABEL_STATUS,
        langDefinition: "LABEL_STATUS",
        column: "status",
        dataType: "icon",
        inputType: "array",
        show: true,
        sequence: 2,
        isEditable: false
      }
    ];

    if (showUserColumn) {
      headers.push({
        title: main.dictionary.LABEL_USER,
        langDefinition: "LABEL_USER",
        column: "changedUser",
        dataType: "string",
        inputType: "string",
        show: true,
        sequence: 3,
        isEditable: false
      });
    }

    return headers;
  },
  getVuelidateErrorMessage(error) {
    var errorMessages = error.split("@");
    var messages = main.dictionary[errorMessages[0]];

    if (errorMessages[1] != null) {
      messages = messages.replace("*num*", errorMessages[1]);
    }

    return messages;
  },
  clearSelectedRows(children) {
    let filter = children.filter((element) => {
      return typeof element.selectedRows !== "undefined";
    });

    filter[0].selectedRows = [];
  },
  prettifyXml(sourceXml) {
    let replacedXml = this.clearXml(sourceXml),
      xmlDoc = new DOMParser().parseFromString(replacedXml, "application/xml"),
      xsltDoc = new DOMParser().parseFromString(
        [
          // describes how we want to modify the XML - indent everything
          '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
          '  <xsl:strip-space elements="*"/>',
          '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
          '    <xsl:value-of select="normalize-space(.)"/>',
          "  </xsl:template>",
          '  <xsl:template match="node()|@*">',
          '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
          "  </xsl:template>",
          '  <xsl:output indent="yes"/>',
          "</xsl:stylesheet>"
        ].join("\n"),
        "application/xml"
      ),
      xsltProcessor = new XSLTProcessor();

    xsltProcessor.importStylesheet(xsltDoc);

    let resultDoc = xsltProcessor.transformToDocument(xmlDoc),
      resultXml = new XMLSerializer().serializeToString(resultDoc);

    return resultXml;
  },
  getBaseUrl(apiName) {
    return require("@/configs/configs." + process.env.NODE_ENV + ".json")[
      apiName
    ].url;
  },
  getApiKey(apiKeyName) {
    return require("@/configs/configs.apiKeys.json")[apiKeyName];
  },
  getValueByKey(obj, key, value) {
    Object.keys(obj).forEach((keyItem) => {
      if (keyItem.toLowerCase() === key.toLowerCase()) {
        value = obj[keyItem];
      } else if (obj[keyItem].constructor.name == "Object") {
        value = this.getValueByKey(obj[keyItem], key, value);
      }
    });

    return value;
  },
  reverseDateTime(dateTime) {
    let newDate;

    if (dateTime.indexOf("-") > -1) {
      if (dateTime.indexOf(" ") > -1) {
        let dates = dateTime.split(" "),
          date = dates[0].split("-");

        newDate = date.reverse().join("-") + " " + dates[1];
      } else {
        let date = dateTime.split("-");

        newDate = date.reverse().join("-");
      }
    } else {
      if (dateTime.indexOf(" ") > -1) {
        let dates = dateTime.split(" "),
          date = dates[0].split(".");

        newDate = date.reverse().join(".") + " " + dates[1];
      } else {
        let date = dateTime.split(".");

        newDate = date.reverse().join(".");
      }
    }

    return newDate;
  },
  toCamelCase(key, value) {
    if (value && typeof value === "object") {
      for (var k in value) {
        if (
          /^[A-Z]/.test(k) &&
          Object.prototype.hasOwnProperty.call(value, k)
        ) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }

    return value;
  },
  activateLoading() {
    store.dispatch("loading/SET_LOADING_STATE", true);
  },
  disableLoading() {
    store.dispatch("loading/SET_LOADING_STATE", false);
  },
  checkAndGetFilterRule(value) {
    let firstCharacter = value.substring(0, 1);
    let lastCharacter = value.substring(value.length - 1);

    if (firstCharacter === "*" && lastCharacter === "*") {
      return "Contains";
    } else if (firstCharacter === "*") {
      return "Ends with";
    } else if (lastCharacter === "*") {
      return "Starts with";
    } else if (!value.includes("*")) {
      return "Equals";
    }
  },
  hasPropertyExistInArray(array, prop) {
    return Object.prototype.hasOwnProperty.call(array, prop);
  },
  convertToStringWithZero(value, numberOfDigit) {
    let returnValue = "",
      length = value.toString().length,
      digit = numberOfDigit - length;

    for (let index = 0; index < digit; index++) {
      returnValue += "0";
    }

    return returnValue + value;
  },
  getCurrentDateAndTime() {
    let currentdate = new Date(),
      datetime =
        "Current date: " +
        currentdate.getDate() +
        "." +
        (currentdate.getMonth() + 1) +
        "." +
        currentdate.getFullYear() +
        " " +
        currentdate.getHours() +
        ":" +
        currentdate.getMinutes() +
        ":" +
        currentdate.getSeconds();

    return datetime;
  },
  clearXml(value) {
    return value
      .replace('"<', "<")
      .replace('>"', ">")
      .replace(/\\"/g, "'")
      .replace(/(?:\\[rnt]|[\r\n\t]+)+/g, "")
      .replace(/\\/g, "");
  },
  isElementInsideGivenParent(element, parentSelector) {
    let result = false,
      nodes = [];

    nodes.push(element);

    while (element.parentNode) {
      nodes.unshift(element.parentNode);
      element = element.parentNode;

      if (element.classList && element.classList.contains(parentSelector)) {
        result = true;
        break;
      }
    }

    return result;
  },
  applyStickyRules(selector) {
    let maxHeight = 500,
      elements = document.querySelectorAll(selector);

    for (let i = 0; i < elements.length; i++) {
      if (window.innerHeight > 0 && elements) {
        let rect = elements[i].getBoundingClientRect(),
          documentHeight = document.body.offsetHeight;

        if (this.isElementInsideGivenParent(elements[i], "v--modal")) {
          maxHeight = window.innerHeight - (documentHeight - rect.top);
        } else {
          // maxHeight = document.body.scrollHeight - window.innerHeight;
          maxHeight = window.innerHeight - 250;
        }

        maxHeight = maxHeight > 0 ? maxHeight : 500;
        maxHeight = maxHeight < 500 ? 500 : maxHeight;
        elements[i].style.overflowY = "scroll";

        if (elements[i].getAttribute("style").indexOf("max-height") === -1) {
          elements[i].style.maxHeight = maxHeight + "px";
        }
      }
    }
  },
  removeClassFromSelector(selector, className) {
    var elements = document.querySelectorAll(selector);

    if (elements.length > 0) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove(className);
      }
    }
  },
  prepareErrorMessage(message) {
    if (this.isNullOrUndefined(message)) {
      return [""];
    }
    let rows = message.split("\n");

    return rows;
  },
  bigNumberFormatter(number) {
    return number.toLocaleString("tr-TR");
  },
  formatBigNumber(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + " Bin";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + " Milyon";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + " Trilyon";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + " KatTrilyon";
  },
  formatBigNumberForUniversal(n) {
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  },
  fileTypes() {
    return [
      "application/msword",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/pjpeg",
      "image/png",
      "image/x-png",
      "text/plain",
      "application/pdf",
      "image/tiff"
    ];
  },
  getComponent(parent, componentName) {
    let component = null;

    while (parent && !component) {
      if (parent.$options.name === componentName) {
        component = parent;
      }

      parent = parent.$parent;
    }

    return component;
  }
};

export default utils;
