<template>
  <div v-if="this.$utils.canShow(name) || !checkAuth">
    <div v-if="isFormGroup" :class="outerDivClass">
      <label :class="labelDivClass" :for="id">{{ text }}</label>
      <div :class="inputDivClass">
        <multiselect
          v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
          :clear-on-select="false"
          :close-on-select="closeOnSelect"
          :disabled="disabled"
          :hide-selected="true"
          :id="id"
          :internal-search="internalSearch"
          :label="label"
          :limit-text="getLimitText"
          :limit="limit"
          :loading="isLoading"
          :max-height="maxHeight"
          :multiple="multiple"
          :name="name"
          :open-direction="openDirection"
          :options-limit="optionsLimit"
          :options="options"
          :placeholder="placeholder"
          :searchable="true"
          :show-no-results="false"
          :track-by="trackBy"
          :value="model"
          @close="close"
          @input="update"
          @search-change="asyncFind"
        >
          <template slot="tag" slot-scope="{ option, remove }">
            <span class="custom__tag">
              <span>{{ option[label] }}</span>
              <span class="custom__remove" @click="remove(option)">
                <i class="las la-backspace" />
              </span>
            </span>
          </template>
          <template slot="clear" slot-scope="props">
            <div
              class="multiselect__clear"
              v-if="
                model != null && (model.length || Object.keys(model).length > 0)
              "
              @mousedown.prevent.stop="clearAll(props.search)"
            >
            </div>
          </template>
          <span slot="noResult">
            No elements found. Consider changing the search query.
          </span>
        </multiselect>
        <div class="disabledField" v-else-if="multiple">
          <span class="custom__tag" v-for="(item, index) in model" :key="index">
            {{ item[label] }}
          </span>
        </div>
        <div type="checkbox" class="disabledField form-control" v-else>
          {{ model[label] }}
        </div>
        <div class="errors" v-if="validator.$error">
          <div class="error" v-for="error in activeErrorMessages" :key="error">
            <span>{{ getError(error) }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!disabled && (this.$utils.canModify(name) || !checkAuth)">
      <multiselect
        :clear-on-select="false"
        :close-on-select="closeOnSelect"
        :hide-selected="true"
        :id="id"
        :internal-search="internalSearch"
        :label="label"
        :limit-text="getLimitText"
        :limit="limit"
        :loading="isLoading"
        :max-height="maxHeight"
        :multiple="multiple"
        :name="name"
        :open-direction="openDirection"
        :options-limit="optionsLimit"
        :options="options"
        :placeholder="placeholder"
        :searchable="true"
        :show-no-results="false"
        :track-by="trackBy"
        :value="model"
        @close="close"
        @input="update"
        @search-change="asyncFind"
      >
        <template slot="tag" slot-scope="{ option, remove }">
          <span class="custom__tag">
            <span>{{ option[label] }}</span>
            <span class="custom__remove" @click="remove(option)">
              <i class="las la-backspace" />
            </span>
          </span>
        </template>
        <template slot="clear" slot-scope="props">
          <div
            class="multiselect__clear"
            v-if="
              model != null && (model.length || Object.keys(model).length > 0)
            "
            @mousedown.prevent.stop="clearAll(props.search)"
          ></div>
        </template>
        <span slot="noResult">
          No elements found. Consider changing the search query.
        </span>
      </multiselect>
      <div class="errors" v-if="validator.$error">
        <div class="error" v-for="error in activeErrorMessages" :key="error">
          <span>{{ getError(error) }}</span>
        </div>
      </div>
    </div>
    <div class="disabledField" v-else-if="model.length > 1">
      <span class="custom__tag" v-for="(item, index) in model" :key="index">
        {{ model[label] }}
      </span>
    </div>
    <div class="disabledField" :id="id" :name="name" v-else>
      {{ model[label] }}
    </div>
  </div>
</template>

<script>
  import { singleErrorExtractorMixin } from "vuelidate-error-extractor";
  import axios from "axios";
  import Multiselect from "vue-multiselect";

  export default {
    name: "CtsMultiselect",
    mixins: [singleErrorExtractorMixin],
    components: {
      Multiselect
    },
    data() {
      return {
        selectedOptions: [],
        options: this.defaultOptions,
        isLoading: false,
        outerDivClass: "form-group ",
        labelDivClass: "control-label col-xs-12 col-sm-12 col-md-12 col-lg-12",
        inputDivClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
        searchParam: {
          SearchObj: "",
          searchParameters: this.searchParameters,
          idList: this.typeIds
        },
        timeout: null
      };
    },
    props: {
      text: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      id: {
        type: String,
        required: true
      },
      isOneLine: {
        type: Boolean,
        default: true
      },
      placeholder: {
        type: String,
        default: ""
      },
      trackBy: {
        type: String,
        required: true
      },
      openDirection: {
        type: String,
        default: "bottom"
      },
      defaultOptions: {
        type: Array,
        default() {
          return [];
        }
      },
      optionsLimit: {
        type: Number,
        default: 300
      },
      limitText: {
        type: String,
        default: " other options"
      },
      maxHeight: {
        type: Number,
        default: 200
      },
      limit: {
        type: Number,
        default: 3
      },
      url: {
        type: String
      },
      model: {
        type: [Array, Object]
        // required: true
      },
      multiple: {
        type: Boolean,
        default: false
      },
      closeOnSelect: {
        type: Boolean,
        default: true
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isFormGroup: {
        type: Boolean,
        default: true
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      css: {
        type: [String, Array],
        default: ""
      },
      labelClass: {
        type: String,
        default: ""
      },
      inputClass: {
        type: String,
        default: ""
      },
      searchParameters: {
        type: Array,
        default() {
          return [];
        }
      },
      internalSearch: {
        type: Boolean,
        default: false
      },
      checkAuth: {
        // for check menu object permission
        type: Boolean,
        default: true
      },
      validator: {
        type: Object,
        default() {
          return {};
        }
      },
      typeIds: {
        type: Array,
        default() {
          return [];
        }
      }
    },
    watch: {
      defaultOptions() {
        this.options = this.defaultOptions;
      },
      searchParameters() {
        this.searchParam.searchParameters = this.searchParameters;
      },
      typeIds() {
        this.searchParam.idList = this.typeIds;
      }
    },
    methods: {
      getLimitText(count) {
        return `and ${count} ${this.limitText}`;
      },
      asyncFind(query) {
        this.searchParam.SearchObj = query;
        this.isLoading = true;

        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          if (
            this.url &&
            !this.internalSearch &&
            (this.searchParam.SearchObj != "" ||
              this.searchParam.searchParameters.length > 0)
          ) {
            axios.post(this.url, this.searchParam).then((response) => {
              this.options = response.data.list;
              this.isLoading = false;
            });
          } else this.isLoading = false;
        }, 500);
      },
      clearAll() {
        if (!this.multiple) {
          this.$emit("change", {});
          this.$emit("input", {});
          this.$emit("close", {});
        } else {
          this.$emit("input", []);
          this.$emit("close", []);
        }

        this.addCss();
      },
      getClass() {
        if (this.isOneLine) {
          this.outerDivClass += "row";
          this.labelDivClass =
            "control-label col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 ";
          this.inputDivClass =
            "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 ";
        }

        if (this.labelClass != "" && this.inputClass != "") {
          this.labelDivClass = "control-label " + this.labelClass + " ";
          this.inputDivClass = this.inputClass + " ";
        }
      },
      update(value) {
        this.$emit("input", value);

        if (!this.multiple) {
          this.$emit("change", value);
        }
      },
      close(value) {
        this.$emit("close", value);
        this.addCss();
      },
      addCss() {
        let self = this,
          domObj = document.getElementById(self.id.toString());

        if (
          !self.$utils.isNullOrUndefined(domObj) &&
          !self.$utils.isNullOrUndefined(domObj.parentElement)
        ) {
          if (
            self.isRequired &&
            domObj.parentElement.className != "multiselect__clear"
          ) {
            domObj.parentElement.className += " requiredField";
          }

          domObj.parentElement.className += " " + self.css;
        }
      },
      getError(error) {
        return this.$utils.getVuelidateErrorMessage(error);
      }
    },
    created() {
      this.getClass();
    },
    updated() {
      this.addCss();
    },
    mounted() {
      this.addCss();
    }
  };
</script>

<style scoped>
  @import "./multiselect.css";
</style>
