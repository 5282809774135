import axios from "axios";
import localStorageService from "@/services/LocalStorageService.js";
import router from "@/router";
import store from "@/store/index";
import utils from "../utils/utils";

const menuHelper = {
  async getDynamicMenus() {
    let userInfo = localStorageService.get("user");

    if (userInfo != null && userInfo != undefined) {
      let user = store.state.login.user,
        roleIds = user.userRoles.map((x) => {
          return x.roleId;
        });

      await axios
        .post("menucomponent/getwithmenu", { RoleIds: roleIds })
        .then((response) => {
          if (utils.isResponseReturnedOk(response)) {
            var list = response.data.list.filter((element) => {
              return element.isActive && !element.isDeleted;
            });
            // Menu urls are case-sensitive.
            // In database menucomponents>url field should be like Role/Form or Role/List
            for (let i = 0; i < list.length; i++) {
              let obj = null;

              if (list[i].url.toLowerCase() === "/home") {
                obj = router.options.routes.filter(
                  (route) => route.path.toLowerCase() === "/home"
                )[0];
                obj.meta.menuId = parseInt(`${list[i].menuId}`);
                obj.meta.componentId = parseInt(`${list[i].id}`);
                obj.meta.table = list[i].tableName;
                obj.path = "/home";
              } else {
                obj = {
                  name: `${list[i].menu.heading}_${list[i].heading}`,
                  path: list[i].heading.toLowerCase().includes("form")
                    ? `/${list[i].url}/:id?`
                    : `/${list[i].url}`,
                  meta: {
                    layout: "default",
                    path: `/${list[i].url.toLowerCase()}`,
                    menuId: parseInt(`${list[i].menuId}`),
                    componentId: parseInt(`${list[i].id}`),
                    isPublic: false,
                    componentPath: `@/views/${list[i].url}.vue`,
                    table: list[i].tableName
                  },
                  component: () => import(`@/views/${list[i].url}.vue`)
                };
              }

              if (list[i].menuComponents.length > 0) {
                list[i].menuComponents.forEach((childComponent) => {
                  let splittedValue = childComponent.url.split("/"),
                    componentName = splittedValue.join("") + "ComponentId";

                  obj.meta[componentName] = childComponent.id;
                });
              }

              router.addRoute(obj);
            }

            store.dispatch("app/REFRESH_HOME");
          }
        })
        .catch((error) => {
          console.log("error on dynamic router!");
          console.log(error);
        });
    }
  }
};

export default menuHelper;
