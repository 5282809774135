<template>
  <nav class="navbar navbar-expand-lg navbar-dark nav-bg">
    <a href="javascript:void(0)">
      <i @click="toggle" class="las la-bars toggle-button" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav" v-if="isBookmarksReady">
        <li
          v-for="bookmark in bookmarks"
          :key="bookmark.id"
          :class="isUrlActive(bookmark.menu.url)"
        >
          <router-link class="nav-link" :to="menuUrl(bookmark.menu.url)">
            {{ $root.dictionary[bookmark.menu.langDefinition] }}
            <span class="sr-only">(current)</span>
          </router-link>
        </li>
      </ul>
      <span class="navbar-nav ml-auto float-right" v-if="isLanguagesReady">
        <CtsMultiselect
          style="min-width: 150px"
          :isFormGroup="false"
          :multiple="false"
          :isOneLine="true"
          :model="languages"
          v-model="languages"
          @change="LanguageChange"
          trackBy="id"
          name="languages"
          label="description"
          text=""
          placeholder="LANGUAGE"
          url="language/GetForMultiselect"
          id="languages"
          :checkAuth="false"
        />
      </span>
      <button
        ref="profileDrop"
        @click="profileDrop = !profileDrop"
        class="btn btn-outline-primary"
        type="button"
        style="
          height: 100½ !important;
          margin-left: 15px;
          color: black;
          background-color: white;
          border: 0px;
          align-items: center;
        "
      >
        <i class="las la-user la-2x" style="size: 35px" />
      </button>
      <CtsDropdownProfile v-show="profileDrop" />
    </div>
  </nav>
</template>

<script>
  import CtsDropdownProfile from "./ui-components/CtsDropdownProfile/CtsDropdownProfile";
  import CtsMultiselect from "./ui-components/CtsMultiselect/CtsMultiselect";

  export default {
    name: "Navbar-Component",
    components: {
      CtsMultiselect,
      CtsDropdownProfile
    },
    data() {
      return {
        selectedLanguageId: 1,
        languages: [],
        profileDrop: false
      };
    },
    computed: {
      isLanguagesReady() {
        if (
          this.$store.state.languageModule.languages == null ||
          this.$store.state.languageModule.languages.length == 0
        ) {
          return false;
        }

        return true;
      },
      isBookmarksReady() {
        if (
          this.$store.state.bookmarksModule.bookmarks == null ||
          this.$store.state.bookmarksModule.bookmarks.length == 0
        ) {
          return false;
        }

        return true;
      },
      bookmarks() {
        var list = this.$store.state.bookmarksModule.bookmarks;

        return list != null && list.length > 10 ? list.slice(0, 10) : list;
      },
      isUrlActive: (vm) => {
        return (menuUrl) => {
          return menuUrl != null &&
            vm.$route.path
              .split("/")
              .filter(
                (e) =>
                  e.toLowerCase() ===
                  menuUrl.slice(1, menuUrl.length).toLowerCase()
              ).length > 0
            ? "nav-item active"
            : "nav-item";
        };
      }
    },
    methods: {
      async GetLanguages() {
        var languages = this.$store.state.languageModule.languages;
        var selectedLanguageId = this.$store.state.languageModule.languageId;

        if (this.$utils.isNullOrUndefined(languages)) {
          await this.$store
            .dispatch("languageModule/GET_LANGUAGES", null, {
              root: true
            })
            .then(() => {
              selectedLanguageId = this.$store.state.languageModule.languageId;
              languages = this.$store.state.languageModule.languages;
            });
        }

        return languages.filter((x) => {
          return x.id == selectedLanguageId;
        });
      },
      async LanguageChange(value) {
        this.$data.selectedLanguageId = this.$utils.isNullOrUndefined(value)
          ? 1
          : value.id;

        await this.$store.dispatch(
          "languageModule/GET_DICTIONARIES",
          this.$data.selectedLanguageId,
          {
            root: true
          }
        );

        this.$data.languages =
          this.$data.languages != (await this.GetLanguages())
            ? await this.GetLanguages()
            : this.$data.languages;
      },
      menuUrl(url) {
        return url != null ? url + "/list" : "javascript:void(0);";
      },
      toggle() {
        const sidebar = document.getElementById("sidebar-wrapper"),
          mainWrapper = document.getElementById("main-wrapper"),
          sidebarMenu = document.getElementById("sidebar-menu");

        if (sidebar.classList.contains("sidebar-wrapper-toggle")) {
          sidebar.classList.remove("sidebar-wrapper-toggle");
          mainWrapper.classList.remove("main-wrapper-toggle");
          sidebar.classList.add("sidebar-wrapper");
          mainWrapper.classList.add("main-wrapper");
          sidebarMenu.style.visibility = "visible";
        } else {
          sidebar.classList.add("sidebar-wrapper-toggle");
          mainWrapper.classList.add("main-wrapper-toggle");
          sidebar.classList.remove("sidebar-wrapper");
          mainWrapper.classList.remove("main-wrapper");
          sidebarMenu.style.visibility = "hidden";
        }
      }
    },
    async mounted() {
      this.$data.languages = await this.GetLanguages();
    }
  };
</script>
