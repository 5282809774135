<template>
  <div v-if="this.$utils.canShow(name) || !checkAuth">
    <div v-if="!isInGrid && !isArray" class="form-group row">
      <template v-if="textAlign == 'Left'">
        <label
          v-if="text != ''"
          :class="[
            'control-label icheck-label',
            labelClass
              ? labelClass
              : 'col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'
          ]"
          :for="id"
        >
          {{ text }}
        </label>
        <div
          :class="
            'col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-9 icheck-' + color
          "
        >
          <input
            v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
            type="checkbox"
            :id="id"
            :name="name"
            :v-model="model"
            :value="model"
            :checked="model"
            @input="update($event)"
            :class="['regular-checkbox big-checkbox', inputClass]"
            :disabled="disabled"
          />
          <img
            v-else-if="model"
            src="@/assets/img/selected_checkbox.jpg"
            width="25"
            height="25"
          />
          <img
            v-else
            src="@/assets/img/unselected_checkbox.jpg"
            width="25"
            height="25"
          />
          <label :for="id"></label>
        </div>
      </template>
      <template v-else>
        <div :class="'col-xs-12 col-sm-8 col-md-8 col-lg-1 icheck-' + color">
          <input
            v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
            type="checkbox"
            :id="id"
            :name="name"
            :v-model="model"
            :value="model"
            :checked="model"
            @input="update($event)"
            :class="['regular-checkbox big-checkbox', inputClass]"
            :disabled="disabled"
          />
          <img
            v-else-if="model"
            src="@/assets/img/selected_checkbox.jpg"
            width="25"
            height="25"
          />
          <img
            v-else
            src="@/assets/img/unselected_checkbox.jpg"
            width="25"
            height="25"
          />
          <label :for="id"></label>
        </div>
        <label
          v-if="text != ''"
          class="control-label col-xs-12 col-sm-4 col-md-4 col-lg-10 icheck-label"
          :for="id"
          >{{ text }}</label
        >
      </template>
    </div>
    <div v-else-if="!isInGrid && isArray" class="form-group row">
      <template v-if="textAlign == 'Left'">
        <label
          v-if="text != ''"
          :class="[
            'control-label icheck-label',
            labelClass
              ? labelClass
              : 'col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3'
          ]"
          :for="id"
        >
          {{ text }}
        </label>
        <div
          :class="
            'col-xs-12 col-sm-12 col-md-6 col-lg-8 col-xl-9 icheck-' + color
          "
        >
          <input
            v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
            :id="id"
            :name="name"
            type="checkbox"
            :value="val"
            v-model="checked"
            @change="onChange"
            :disabled="disabled"
            :class="inputClass"
          />
          <img
            v-else-if="model"
            src="@/assets/img/selected_checkbox.jpg"
            width="25"
            height="25"
          />
          <img
            v-else
            src="@/assets/img/unselected_checkbox.jpg"
            width="25"
            height="25"
          />
          <slot />
          <label :for="id"></label>
        </div>
      </template>
      <template v-else>
        <div :class="'col-xs-12 col-sm-8 col-md-8 col-lg-1 icheck-' + color">
          <input
            v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
            :id="id"
            :name="name"
            type="checkbox"
            :value="val"
            v-model="checked"
            @change="onChange"
            :disabled="disabled"
            :class="inputClass"
          />
          <img
            v-else-if="model"
            src="@/assets/img/selected_checkbox.jpg"
            width="25"
            height="25"
          />
          <img
            v-else
            src="@/assets/img/unselected_checkbox.jpg"
            width="25"
            height="25"
          />
          <slot />
          <label :for="id"></label>
        </div>
        <label
          v-if="text != ''"
          class="control-label col-xs-12 col-sm-4 col-md-4 col-lg-10 icheck-label"
          :for="id"
        >
          {{ text }}
        </label>
      </template>
    </div>
    <div v-else-if="isInGrid && isArray">
      <template>
        <div :class="'icheck-' + color + ' no-margin'">
          <input
            v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
            :id="id"
            :name="name"
            type="checkbox"
            :value="val"
            v-model="checked"
            @change="onChange"
            :disabled="disabled"
            :class="inputClass"
          />
          <img
            v-else-if="model"
            src="@/assets/img/selected_checkbox.jpg"
            width="25"
            height="25"
          />
          <img
            v-else
            src="@/assets/img/unselected_checkbox.jpg"
            width="25"
            height="25"
          />
          <label :for="id"></label>
        </div>
      </template>
    </div>
    <div v-else>
      <template>
        <div
          :class="'icheck-' + color"
          style="margin: 0 !important; padding: 0 !important"
          v-if="!disabled && (this.$utils.canModify(name) || !checkAuth)"
        >
          <input
            type="checkbox"
            :id="id"
            :name="name"
            :v-model="model"
            :value="model"
            :checked="model"
            @input="update($event)"
            class="regular-checkbox big-checkbox hide"
            :disabled="disabled"
            :class="inputClass"
          />
          <label :for="id"></label>
        </div>
        <img
          v-else-if="model"
          src="@/assets/img/selected_checkbox.jpg"
          width="23"
          height="23"
          style="margin-left: -5px"
        />
        <img
          v-else
          src="@/assets/img/unselected_checkbox.jpg"
          width="23"
          height="23"
          style="margin-left: -5px"
        />
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CtsCheckbox",
    data() {
      return {
        checkedProxy: false
      };
    },
    props: {
      text: {
        type: String,
        default: ""
      },
      name: {
        type: String,
        default: ""
      },
      id: {
        type: [String, Number],
        default: ""
      },
      model: {
        type: [Boolean, Array]
      },
      textAlign: {
        type: String,
        default: "Left"
      },
      color: {
        type: String,
        default: "primary"
      },
      disabled: {
        type: Boolean,
        default: false
      },
      isInGrid: {
        type: Boolean,
        default: false
      },
      isArray: {
        type: Boolean,
        default: false
      },
      val: {
        type: [String, Array, Number, Boolean]
      },
      value: {
        type: [String, Array, Number, Boolean]
      },
      labelClass: {
        type: String,
        default: null
      },
      checkAuth: {
        // for check menu object permission
        type: Boolean,
        default: true
      },
      inputClass: {
        type: String,
        default: ""
      }
    },
    computed: {
      checked: {
        get() {
          return this.value;
        },
        set(val) {
          this.checkedProxy = val;
        }
      }
    },
    methods: {
      update(event) {
        this.$emit("input", event.target.checked);
        this.$emit("change", event);
      },
      onChange: function () {
        this.$emit("input", this.checkedProxy);
        this.$emit("change", event);
      }
    }
  };
</script>

<style scoped>
  @import "./checkbox.css";
</style>
