<template>
  <div id="sticky-toolbar" class="row" v-if="getAvailableToolbar.length > 0">
    <div class="col toolbar-wrapper">
      <div class="float-left">
        <div class="toolbar">
          <div class="btn-group" role="group">
            <button
              type="button"
              :class="[
                'btn',
                !$utils.isNullOrWhiteSpace(item.color)
                  ? item.color
                  : 'btn-toolbar'
              ]"
              v-for="(item, index) in getAvailableToolbar"
              :id="item.objectIdentifier"
              :key="index"
              :name="item.objectIdentifier"
              :style="getButtonStyle(item)"
              :tooltip="$root.dictionary[item.langDefinition]"
              @click.stop="toolbarClick(item.toolbarFunction)"
              effect="dark"
            >
              <i :class="item.icon" :style="getIconStyle(item)"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
    <cts-modal
      :clickToClose="false"
      :draggable="false"
      :name="'ctsmodal' + table"
      transition="pop-out"
    >
      <GridOptions :componentId="this.componentId" :table="table" />
    </cts-modal>
  </div>
</template>

<script>
  import app from "@/common/constants/app";
  import CtsModal from "./ui-components/CtsModal/CtsModal";
  import GridOptions from "./data-table/GridOptions";
  import store from "@/store";

  export default {
    name: "toolbarComponent",
    props: {
      componentId: {
        type: [String, Number]
      },
      table: {
        type: String
      }
    },
    components: {
      "cts-modal": CtsModal,
      GridOptions
    },
    data() {
      return {
        toolbar_items: null
      };
    },
    methods: {
      getButtonStyle(item) {
        return !this.$utils.isNullOrWhiteSpace(item.color)
          ? `backgroundColor:${item.color} `
          : "";
      },
      getIconStyle(item) {
        return !this.$utils.isNullOrWhiteSpace(item.color)
          ? `color:white `
          : "";
      },
      toolbarClick(functionName) {
        if (functionName.indexOf("deleteFunction") === -1) {
          store.dispatch("loading/SET_LOADING_STATE", true);
        }

        if (functionName.indexOf("saveFunction") > -1) {
          let status = this.$utils.checkAndRunValidation(this);

          if (status.length === 0) {
            eval(functionName);
          } else {
            this.$utils.disableLoading();
          }
        } else if (functionName.indexOf("this") === -1) {
          eval("this.$parent." + functionName);
        } else {
          eval(functionName);
        }
      },
      viewFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.viewFunction();
      },
      addFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.addFunction();
      },
      copyFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.copyFunction();
      },
      saveFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.saveFunction();
      },
      updateFunction() {
        this.$parent.updateFunction();
      },
      editFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.editFunction();
      },
      deleteFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.deleteFunction();
      },
      downloadFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.downloadFunction();
      },
      exportFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.exportFunction();
      },
      importFunction() {
        this.$parent.importFunction();
      },
      sendMailFunction() {
        this.$parent.sendMailFunction();
      },
      openModalFunction() {
        this.$parent.openModalFunction();
      },
      clearanceParameterFunction() {
        this.$parent.clearanceParameterFunction();
      },
      extraFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        // if (!this.$utils.isNullOrUndefined(event.currentTarget)) {
        //   eval("this." + event.currentTarget.id.split("_")[1]);
        // } else {
        //   eval("this.$parent" + "");
        // }

        eval("this.$parent" + "");
        this.$parent.extraFunction();
      },
      specialFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        // if (!this.$utils.isNullOrUndefined(event.currentTarget)) {
        //   eval("this." + event.currentTarget.id.split("_")[1]);
        // } else {
        //   eval("this.$parent" + "");
        // }

        eval("this.$parent" + "");
        this.$parent.specialFunction();
      },
      showLogsFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.showLogsFunction();
      },
      convertActivePassive() {
        this.$parent.convertActivePassive();
      },
      filterFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.filterFunction();
      },
      addDocumentFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.addDocumentFunction();
      },
      viewDocumentFunction() {
        // Do not need to emit, because toolbar and slot is in same level!
        this.$parent.viewDocumentFunction();
      },
      downloadImportTemplateFunction() {
        this.$parent.downloadImportTemplateFunction();
      },
      async gridOptions() {
        this.$modal.show("ctsmodal" + this.table);

        // let status = this.$utils.checkAndRunUnSavedData(this); //TODO-> Editable listeler için kaydedilmemiş data varmı kontrol edilecek.
        // if (status) {
        //   this.$modal.show("ctsmodal" + this.table);
        // } else {
        //   this.$utils.showWarningMessage(
        //     this.$root.dictionary.MSG_SHOULD_SAVE_CHANGES_FIRST
        //   );
        //   this.$utils.disableLoading();
        // }
      }
    },
    watch: {
      $route() {}
    },
    computed: {
      getAvailableToolbar: function () {
        if (this.$store.state.menuObject.menuObjects.length > 0) {
          let list = this.$store.state.menuObject.menuObjects.filter(
            (element) =>
              element.menuObjectTypeId === app.menuObjectTypes.toolbar &&
              element.table === this.table &&
              (element.menuComponentId === parseInt(this.componentId) ||
                element.menuComponentId == null)
          );

          list = list.filter((element) => {
            let canShow =
              this.$objectPermissionHelper.checkMenuObjectPermissionsSee(
                element.objectIdentifier
              );

            if (canShow) {
              return element;
            }
          });

          return list;
        } else {
          return [];
        }
      }
    }
  };
</script>

<style scoped>
  @import "../styles/toolbar.css";
</style>
